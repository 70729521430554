@import 'global';

// Start of smartphone queries only
@media (max-width: 768px) {
    body {
        background: rgba(252, 246, 237, 1) !important;
    }

    .AirIntro {
        display: none;
    }

    .MainLayout {
        background: rgba(252, 246, 237, 1) !important;
        display: block;

        width: 92% !important;
        min-width: 92% !important;
        max-width: 92% !important;
        margin: 0px auto;
    }

    .MainSheet {
        background: rgba(252, 246, 237, 1) !important;
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        .SheetContent {
            width: 100%;
            margin: auto;
            padding: 0 0px;
        }
    }

    .Headline {
        .tagline {
            font-size: 40pt;
        }

        .subtag {
            font-size: 19pt;
        }
    }

    .CTA {
        background-color: #ddd !important;
        text-decoration: none;
        width: 100%;
    }

    .below-cta {
        width: 100%;
    }

    .MobileCTA {
        display: block !important;
    }

    .mobile-notice {
        padding: 40px 0 0 0;
        font-size: 14.5pt;
        color: darken($negativeColor, 8%);
        font-weight: 500;
    }

    .DesktopCTA {
        display: none !important;
    }
}

.MobileCTA {
    display: none;
}

// End of smartphone queries


@media (max-height: 860px) {
    .AirIntro .ai-face-content {
        margin-top: 22px !important;
    }

    .Headline {
        padding-top: 2px !important;
    }
}

@media (max-height: 740px) {
    .AirIntro .ai-face-content {
        margin-top: 4px !important;
    }

    .Headline {
        padding-top: 0px !important;
    }
}

@media (max-height: 700px) {
    .AirIntro .ai-face-content {
        margin-top: -7px !important;
    }

    .Headline {
        padding-top: 0px !important;
        margin-top: -4px !important;
    }
}

@media (max-width: 720px) {

    .mo {
        display: block !important;
    }

    .do {
        display: none !important;
    }

    .App {
        max-width: 100vh;
    }

    .Headline {
        padding: 6px;

        .heading {
            font-size: 27pt;
        }

        .heading-good {
            font-size: 41pt;
        }

        .subheading {
            max-width: 330px;
            font-size: 15pt;
        }

        .subheading-profits {
            width: auto;
            max-width: 330px;
            margin-left: 0px;

            margin-top: 100px;

            font-size: 12pt;
        }

        .icon-position {
            .flex-icon-img {
                width: 120px;
                left: 310px;
                top: -80px;
            }

            .scream-icon-img {
                display: none;
            }
        }
    }

    .BrowserShot-position {
        display: none;
    }

    .BrowserShot {
        left: 40px;
        top: 540px;

        .browser-img {
            width: 480px;
        }
    }
}

.mo {
    display: none;
}

.do {
    display: block;
}