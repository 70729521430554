@import 'global';

body {
    background-color: lighten(#fff, 4%);
    background-color: #fff;
    font-family: "Manrope" !important;

    //background-color: $backgroundColor !important;
    background: linear-gradient(0deg, rgba(237, 246, 255, 1) 0%, rgba(237, 246, 255, 1) 80%, rgba(252, 246, 237, 1) 100%) no-repeat;
    height: 100vh;
}

$pageWidth: 100%;

.MainLayout {
    display: flex;
    width: $pageWidth;
    max-width: $pageWidth;
    min-width: $pageWidth;
    margin: 0 auto;
}

.MainSheet {
    background-color: lighten(#fff3e6, 3%);
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 100vh;
    //padding-left: 220px
    box-sizing: border-box;
}

.terms-and-conditions {
    font-size: 10.25pt;
    padding: 6px 0 0 0;
    padding-right: 48px;
    text-align: center;
    color: #333;

    a:link {
        color: #333;
    }

    a:visited {
        color: #333;
    }
}

.SheetContent {
    width: 460px;
    margin-left: auto;
}

.Logo {
    .logo-img {
        margin: 34px 0;
        margin-left: -8px;
        width: 92px;
    }
}

.Headline {

    .tagline {
        padding-right: 22px;
        font-family: 'Manrope', sans-serif;
        font-size: 43pt;
        letter-spacing: -2.25px;
        line-height: 1.1em;
        color: #111;
        font-weight: 800;
        color: darken($negativeColor, 12%);
        color: #111;
    }

    .subtag {
        padding: 12px 40px 8px 0;
        font-family: 'Merriweather', serif;
        font-size: 19.75pt;
        letter-spacing: -.75px;
        line-height: 1.25em;
        font-weight: 500;
        color: darken($positiveAccentColor, 2%);
        //text-transform: capitalize;
        //color: rgb(251, 81, 59);
        //color: #777;
    }
}

.CTA {
    width: 90%;
    border-radius: 8px;
    background-color: $negativeColor;
    text-decoration: underline;
    text-decoration-color: $negativeColor;
    //background-color: darken($negativeColor, 10%);
    font-family: 'Manrope';
    font-size: 17pt;
    font-weight: 800;
    letter-spacing: -.5px;
    line-height: 1.1em;
    color: #fff;
    text-align: center;
    margin: 22px 0 0 0;
    padding: 18px;
    box-sizing: border-box;
    cursor: pointer;

    transition: .2s;
}

.CTA:hover {
    background-color: darken($negativeColor, 4%);
    text-decoration: underline;
    text-decoration-color: lighten(#2596be, 32%);
}

.below-cta {
    width: 90%;
    padding-top: 12px;
    padding-bottom: 0px;
    box-sizing: border-box;
    font-size: 12.5pt;
    color: #111;
    font-weight: 700;
    text-align: center;
    // text-decoration: underline;
    // text-decoration-color: lighten(#f59c00, 30%);
    // text-decoration-thickness: 2.5px;

}

.below-cta-terms {
    font-size: 11.25pt;
    font-weight: 700;
    padding: 14px 0 0 0;
    padding-right: 48px;
    text-align: center;
    color: #333;
}

.disclaimer-cta {
    //background-color: #fff;
    //border: 2px solid #eee;
    border-radius: 16px;
    margin: 0px 0px 0 0;

    padding: 2px 20px;

    font-size: 11pt;
    text-align: center;
    width: 362px;

    .air-name {
        //color: $negativeColor;
        font-weight: 700;
    }
}

.legal-link {
    color: #333 !important;
}

.legal-link:link {
    color: #333 !important;
}


.face-img {
    margin-top: 20px;
    margin-left: 50px;
    margin: 50px auto 10px auto;
    z-index: 100;
    //opacity: 0 !important;




    position: relative;
    top: 52px;
    left: -49px;
}

.face-top-img {
    position: relative;
    top: 81px;
    left: 62.5px;
    //animation: welcomeWiggle 2.5s ease-in-out infinite;

    width: 122px;


    animation-name: welcomeHighFloating;
    animation-delay: .0s;
    animation-duration: 2.55s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    z-index: 1000;
}

.face-bottom-img {
    position: relative;
    width: 122px;
    top: 88px;
    left: -58px;
    animation: welcomeWiggle 2.55s ease-in-out infinite;
    animation-delay: 2s;
    z-index: 1000;
}



@keyframes welcomeWiggle {

    0%,
    7% {
        transform: rotateZ(0);
    }

    15% {
        transform: rotateZ(-15deg);
        filter: brightness(1.0);
        filter: brightness(1.1);
    }

    20% {
        transform: rotateZ(10deg);
    }

    25% {
        transform: rotateZ(-10deg);

    }

    30% {
        transform: rotateZ(6deg);

    }

    35% {
        transform: rotateZ(-4deg);

    }

    60% {
        filter: brightness(1.0);
    }

    40%,
    100% {
        transform: rotateZ(0);
    }
}

@keyframes welcomeHighFloating {
    0% {
        transform: translate(0px, 0px);
        filter: brightness(1.2);
    }

    30% {
        filter: brightness(1);
    }

    40% {
        transform: translate(0px, -31px);
        filter: brightness(1);
    }

    70% {
        filter: brightness(1);
    }

    100% {
        transform: translate(0px, -0px);
        filter: brightness(1.2);
    }
}

.PageLoadingOverlay {
    background-color: #fff;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
}

.MainLayout.IsOverCta {
    .arrow-img {
        opacity: .35;

    }

    .AirIntro {
        //opacity: .35;
    }
}

.AirIntro {
    transition: .2s;
}

.arrow-img {
    transition: .2s;
}